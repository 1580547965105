import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import duomobile from '../assets/duomobile.svg';
import duocard from '../assets/duocard.svg';
import transactionhistory from '../assets/transactionhistory.svg';
import home from '../assets/home-icon.svg';
import DUOlogo from '../assets/whiteduologo.svg';
import axiosCall from '../axios/axios';
import { useForm } from 'react-hook-form';
import Loader from './Loader';
import remap from '../assets/remap1.svg';
import { Modal, Button } from 'react-bootstrap';




const Remap = () => {
    const { handleSubmit } = useForm();
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState(false);
    const [creditStatusData, setCreditStatusData] = useState([]);
    const [cards, setCards] = useState([]);
    const [getProfile, setGetProfile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [errormsg, setErrormsg] = useState('');

    let history = useHistory();
    useEffect(() => {
        setIsLoading(true);
        (async () => {

            const body = {
                "ACCOUNTNUMBER": localStorage.getItem("AccountNo")
            }
            await axiosCall.POST('/customer/getduocustomerinfo', body)
                .then((response1) => {
                    if (response1.data.status === "200" && response1.data.message === "SUCCESS") {
                        setCreditStatusData([response1.data.data]);
                        setCards(response1.data.data.EXISTING_CARDS);
                        setIsLoading(false);
                    }
                }).catch(err => {
                    console.error(err);
                    setIsLoading(false);
                });
        })();
    }, [])


    const remapProfile = () => {

        const body = {
            "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
            "PAN_LIST": panlistArray
        }
        axiosCall.POST('/customer/remapcustomerprofilev', body)
            .then((response) => {
                if (response.data.STATUS === "200" && response.data.MESSAGE === "SUCCESS") {
                    history.push({
                        pathname: `/RemapSuccess1`,
                        state: { panlistData: indexArray, creditStatusData: creditStatusData }
                    });
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
                setErrormsg("Please try After Sometime");
                setModalOpen(true);
            });
    }
    let indexArray = null;
    let panlistArray = null;
    let panlistPAN = null;

    const onSubmit = async () => {
        setIsLoading(true);
        const body = {
            "ACCOUNTNUMBER": localStorage.getItem("AccountNo"),
        }
        await axiosCall.POST('/customer/getallcustomercardsv', body)
            .then((response) => {
                if (response.data.STATUS === "200" && response.data.MESSAGE === "SUCCESS") {
                    setIsLoading(false);
                    setData([response.data.CARDS]);
                    indexArray = response.data.CARDS.map((item, index) =>
                        item.PAN
                    );
                    panlistArray = indexArray.map((cards, index) =>
                        cards.ENCRYPTEDPAN
                    )
                    panlistPAN = indexArray.map((list, index) =>
                        list.MASKEDPAN
                    )
                    setData(panlistArray);
                    setGetProfile(indexArray);
                    if (panlistArray.length <= 6) {
                        remapProfile()
                    } else {
                        setSuccess(true);
                    }

                } else {
                    setIsLoading(false);
                    setModalOpen(true);
                }
            }).catch((error) => {
                console.log(error);
                setIsLoading(false);
                setErrormsg("Please try After Sometime");
                setModalOpen(true);
            });
        setTimeout(() => {
        }, 1000);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleRedirect = () => {
        handleCloseModal();
    };
    if (success) {
        return (
            <Redirect
                to={{
                    pathname: "/RemapDone",
                    state: { data: data, getProfile: getProfile, creditStatusData: creditStatusData }
                }}>
            </Redirect>
        )

    } else {

        return (
            <React.Fragment>
                <Navbar className="navbar navbar-expand-lg navbar-dark bg-dark" bg="dark" expand="lg">
                    <div className="navi">
                        <ul className='ulnavicon'>
                            <li className='linavigation imgduologo'> <img className="duosublogotopmain" src={DUOlogo} alt="logo" /></li>
                            <Link to="/Home">  <li className='linavigation'> <img className="duosublogotop " src={home} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=VirtualCreditCard">  <li className='linavigation'> <img className="duosublogotop " src={duomobile} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=TransactionHistory">   <li className='linavigation'> <img className="duosublogotop " src={transactionhistory} alt="logo" /></li> </Link>
                            <Link to="/VerifyDuoPin?page=RepaymentRequest">  <li className='linavigation'> <img className="duosublogotop " src={duocard} alt="logo" /></li></Link>
                            <Link to="/VerifyDuoPin?page=Remap">  <li className='linavigation remaplogo'> <img className="duosublogotop " src={remap} alt="logo" /></li></Link>
                            {/* <Link to="/VerifyDuoPin?page=ApplyDuoCard">  <li className='linavigation'> <img className="duosublogotop " src={cardnumber} alt="logo" /></li></Link>
                         */}
                        </ul>
                    </div>
                </Navbar>
                <p className='homesummery'>View / Update Linked Debit Cards (DTS)</p>
                <div>
                    {modalOpen && (
                        <Modal show={modalOpen} handleClose={handleCloseModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Alert</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='modaltext'>{errormsg}</p>
                                <hr className='hrline' />
                            </Modal.Body>
                            <Modal.Footer onClick={handleRedirect}>
                                <Button variant="secondary" >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </div>
                {isLoading ? <div>
                    <div className='container remapview'>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div>
                                                <div className="container-listitem remap-text">
                                                    <div className="listitemremap">
                                                        <p className='remaplist'> Virtual Credit Card Number</p>
                                                    </div>

                                                    <div className="listitemremap1">
                                                        <p className='remaplist'>{item.CREDITCARD.PAN}</p>
                                                    </div>
                                                </div>

                                                <div className="container-listitem Remapdiv ">
                                                    <div className="listitemremap">
                                                        <p className='remaplist'> Bank Account Number</p>
                                                    </div>

                                                    <div className="listitemremap1">
                                                        <p className='remaplist'> {"XXXXXXX" + item.ACCOUNT.slice(-4).repeat(1)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>

                                <div>
                                    <p className="remapdebitnew2">Following Debit Cards attached to your bank account number are linked to this virtual Credit Card.</p>

                                    <div>
                                        <ul className='listremap'>
                                            {cards.map((item) => (
                                                <li className='listremap1'>{item.PAN}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <p className="remapdebitnew">  Dont have your Debit Card listed above ?</p>
                                <p className="remapdebitnew"> Click at <b>Remap / Link all my Debit Cards </b> button below for us to link your <b>Virtual Credit Card </b> to all the
                                    Debit Cards that are attached to your Bank Account </p>
                                <Loader />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='register-button'>
                                        <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Remap / Link all my Debit Cards" />

                                    </div>
                                </form>

                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className='container remapview'>
                        <div>
                            {modalOpen && (
                                <Modal show={modalOpen} handleClose={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Alert</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p className='modaltext'>{errormsg}</p>
                                        <hr className='hrline' />
                                    </Modal.Body>
                                    <Modal.Footer onClick={handleRedirect}>
                                        <Button variant="secondary" >
                                            OK
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            )}
                        </div>
                        <div className='row'>
                            <div className='col-md-3'>
                            </div>
                            <div className='col-md-6'>
                                <div>
                                    {creditStatusData && <div>
                                        {creditStatusData.map((item, i) => (
                                            <div key={item.id}>
                                                <div className="container-listitem remap-text">
                                                    <div className="listitemremap">
                                                        <p className='remaplist'> Virtual Credit Card Number</p>
                                                    </div>

                                                    <div className="listitemremap1">
                                                        <p className='remaplist'>{item.CREDITCARD.PAN}</p>
                                                    </div>
                                                </div>

                                                <div className="container-listitem Remapdiv ">
                                                    <div className="listitemremap">
                                                        <p className='remaplist'> Bank Account Number</p>
                                                    </div>

                                                    <div className="listitemremap1">
                                                        <p className='remaplist'> {"XXXXXXX" + item.ACCOUNT.slice(-4).repeat(1)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                </div>

                                <div>
                                    <p className="remapdebitnew2">Following Debit Cards attached to your bank account number are linked to this virtual Credit Card.</p>

                                    <div>
                                        <ul className='listremap'>
                                            {cards.map((item) => (
                                                <li className='listremap1'>{item.PAN}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <p className="remapdebitnew">  Dont have your Debit Card listed above ?</p>
                                <p className="remapdebitnew"> Click at <b>Remap / Link all my Debit Cards </b> button below for us to link your <b>Virtual Credit Card </b> to all the
                                    Debit Cards that are attached to your Bank Account </p>

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='register-button'>
                                        <input type="submit" className="btn btn-warning nextbuttonpinRey" value="Remap / Link all my Debit Cards" />

                                    </div>
                                </form>
                            </div>
                            <div className='col-md-3'>
                            </div>
                        </div>
                    </div>}

            </React.Fragment>
        )
    }
}
export default Remap;